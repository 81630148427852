<template>
  <div class="row" v-if="checkPer('students|stats')">
    <div class="col-12" v-if="favs.length">
      <div class="row" v-if="checkPer('*')">
        <div class="col-12">
          الاختصارات
        </div>
        <div class="col-12 col-lg-4 g" v-for="fav in favs" :key="fav.title + fav.url">
          <button @click="$router.push(fav.url)" class="btn btn-block btn-outline-secondary bg-white favbtn">
            <i class="fa fa-star"></i>
            {{ fav.title }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-2 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-check-square"></i>
            اجمالي المتواجدين
          </h5>
        </div>
        <div class="card-body bg-success" style="border-radius: 0px 0px 10px 10px !important">
          <span style="font-size: 20px" class="text-white">
            {{ reports[1] || reports[2] ? (reports[1] ? reports[1] : 0) + (reports[2] ? reports[2] : 0) : 0 }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-2 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-check"></i>
            عدد الحضور
          </h5>
        </div>
        <div class="card-body bg-info" style="border-radius: 0px 0px 10px 10px !important">
          <span style="font-size: 20px" class="text-white">
            {{ reports[1] ? (reports[1] ? reports[1] : 0) : 0 }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-2 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-clock-o"></i>
            عدد المتأخرين
          </h5>
        </div>
        <div class="card-body bg-warning" style="border-radius: 0px 0px 10px 10px !important">
          <span style="font-size: 20px" class="text-white">
            {{ reports[2] ? reports[2] : 0 }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-3 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-user-times"></i>
            عدد الغياب
          </h5>
        </div>
        <div class="card-body" style="border-radius: 0px 0px 10px 10px !important; border-top: 1px solid #333">
          <div class="row">
            <div class="col-6 text-center">
              <span style="font-size: 20px" class="text-danger">
              {{ reports[3] ? reports[3] : 0 }} بدون عذر
            </span>
            </div>
            <div class="col-6 text-center">
              <span style="font-size: 20px" class="text-primary">
              {{ reports[5] ? reports[5] : 0 }} بعذر 
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-2 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-sign-out"></i>
            عدد الانصرافات
          </h5>
        </div>
        <div class="card-body bg-secondary" style="border-radius: 0px 0px 10px 10px !important">
          <span style="font-size: 20px" class="text-white">
            {{ reports[4] ? reports[4] : 0 }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-1 g">
      <div class="card" style="border-radius: 10px !important">
        <div class="card-hedaer g">
          <h5>
            <i class="fa fa-users"></i>
            الكل
          </h5>
        </div>
        <div class="card-body" style="border-radius: 0px 0px 10px 10px !important; background: #999">
          <span style="font-size: 20px" class="text-white">
            {{ students }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4" v-if="loaded" style="z-index: 9">
      <div id="chartContainer"></div>
    </div>
    <div class="col-12 col-lg-4" v-if="loaded" style="z-index: 9">
      <div class="d-block d-lg-none">
        <br><br><br><br>
        <br><br><br><br>
        <br><br><br><br>
        <br><br><br><br>
        <br><br><br><br>
        <br>
      </div>
      <div id="chartContainer2"></div>
    </div>
    <div class="col-12 col-lg-4" v-if="loaded" style="z-index: 9">
      <div class="d-block d-lg-none">
        <br><br><br><br>
        <br><br><br><br>
        <br><br><br><br>
        <br><br><br><br>
        <br><br><br><br>
        <br>
      </div>
      <div id="chartContainer3"></div>
    </div>
    <div class="col-12" v-if="loaded">
      <br><br><br><br>
      <br><br><br><br>
      <br><br><br><br>
      <br><br><br><br>
      <br><br><br><br>
      <br>
      <div id="chartContainer4"></div><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
      <div class="col-12">
        <div class="row">
          <div class="col-12 text-center g">
            <button class="btn btn-lg btn-success" @click="$router.push('/_students/reports/classrooms')">
              <i class="fa fa-pie-chart"></i>
              عرض احصائيات الفصول
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import CanvasJS from '@canvasjs/charts';

export default {
  components: {
    VueApexCharts,
  },
  data(){
    return {
      loaded: false,
      students: 0,
      user: JSON.parse(localStorage.getItem('user')),
      messages: 0,
      reports: {},
      favs: [],
      methodsx: {},
      classes: [],
      attend: [],
      ghiab: [],
      late: []
    }
  },
  created(){
      var g = this;
      var x1 = setInterval(() => {
        if(g.loaded){
          clearInterval(x1)
          setTimeout(() => {
        var chart = new CanvasJS.Chart("chartContainer", {
          theme: "light2",
          animationEnabled: true,
          title:{
            text: "احصائيات الغياب"
          },
          styles: {
          width: "100%",
          height: "360px"
        },
          data: [{
            type: "pie",
            indexLabel: "{label} (#percent%)",
            yValueFormatString: "#,##0",
            toolTipContent: "<span style='\"'color: {color};'\"'>{label}</span> {y} (#percent%)",
            dataPoints: [
              { label: "الحضور", y: g.reports[1] },
              { label: "التأخر", y: g.reports[2] },
              { label: "الغياب", y: g.reports[3] },
              { label: "الانصراف", y: g.reports[4] },
              { label: "غياب بعذر", y: g.reports[4] },
            ].map(function(a){
              if(a.y > 0){
                return a;
              }else{
                return false;
              }
            }).filter((x)=>{return x;})
          }]
        },
        
        );
        chart.render();
        var chart = new CanvasJS.Chart("chartContainer3", {
          theme: "light2",
          animationEnabled: true,
          styles: {
          width: "100%",
          height: "360px"
        },
          title:{
            text: "نسبة الغياب في الصفوف"
          },
          data: [{
            type: "pie",
            indexLabel: "{label} (#percent%)",
            yValueFormatString: "#,##0",
            toolTipContent: "<span style='\"'color: {color};'\"'>{label}</span> {y} (#percent%)",
            dataPoints: g.classes.map(function(a){
              if(a.y > 0){
                return a;
              }else{
                return false;
              }
            }).filter((x)=>{return x;})
          }]
        },
        );
        chart.render();
        chart = new CanvasJS.Chart("chartContainer2", {
          theme: "light2",
          animationEnabled: true,
          title:{
            text: "طرق التحضير"
          },
          styles: {
          width: "100%",
          height: "360px"
        },
          data: [{
            type: "doughnut",
            indexLabel: "{label} (#percent%)",
            yValueFormatString: "#,##0",
            toolTipContent: "<span style='\"'color: {color};'\"'>{label}</span> {y} (#percent%)",
            dataPoints: [
              { label: "باركود", y: g.methodsx.barcode },
              { label: "يدوي", y: g.methodsx.manual },
              { label: "البصمة", y: g.methodsx.fp },
              { label: "تحضير جماعي", y: g.methodsx.bulk }
            ].map(function(a){
              if(a.y > 0){
                return a;
              }else{
                return false;
              }
            }).filter((x)=>{return x;})
          }]
        },
        );
        chart.render();
        setTimeout(() => {
          var chartx = new CanvasJS.Chart("chartContainer4", {
          theme: "light2",
          animationEnabled: true,
          title: {
            text: "نسبة الحضور والغياب على مستوى الاسبوع"
          },
          axisY: {
            title: "نسبة الحضور والغياب",
          },
          axisX: {
            valueFormatString: "DD MMM"
          },
          toolTip: {
            shared: true
          },
          legend: {
            cursor: "pointer",
            itemclick: this.toggleDataSeries
          },
          data: [{
            type: "splineArea",
            name: "الحضور",
            showInLegend: true,
            color: "#26C6DA",
            xValueType: "dateTime",
            dataPoints: g.attend
          }, {
            type: "splineArea",
            name: "التأخر",
            showInLegend: true,
            color: "#EABE6C",
            xValueType: "dateTime",
            dataPoints: g.late
          }, {
            type: "splineArea",
            name: "الغياب",
            showInLegend: true,
            color: "#9FA8DA",
            xValueType: "dateTime",
            dataPoints: g.ghiab
          }]
        },
        );
        chartx.render();
        }, 100);
      }, 100);
        } 
      });
      g.getStats()
      if(localStorage.getItem("favs")){
        g.favs = JSON.parse(localStorage.getItem('favs'))
      }
  },
  methods: {
    refresh(){
      location.reload()
    },
    getStats(){
      var g = this;
      $.post(api + '/user/students/stats', {
          jwt: this.user.jwt
      }).then(function(r){
          g.loading = false
          if(r.status != 100){
              alert(r.response)
          }else{
              g.students = r.response.students
              g.messages = r.response.messages
              g.reports = r.response.reports
              g.loaded = true
              g.methodsx = r.response.methodsx
              g.classes = r.response.classes
              g.attend = r.response.attend
              g.ghiab = r.response.ghiab
              g.late = r.response.late
          }
      }).fail(function(){
          g.loading = false;
      })
    }
  }
}
</script>

<style>
.canvasjs-chart-credit{
  display: none !important;
}
.favbtn:hover{
  background: #eee !important;
}
</style>